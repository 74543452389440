const TYPE = {
  LOAD_USER_ORGANIZATION: 'LOAD_USER_ORGANIZATION',
}

export default {
  namespaced: true,
  state: {
    userOrganization: null,
    organizationViewModal: null,
  },
  getters: {
    userOrganizationRows(state) {
      if (!state.userOrganization) return []
      return state.userOrganization.map(item => ({
        id: item.id,
        organization: item.name,
        isActive: item.active,
        managers: item.managers
          .map(manager => (manager.user_active ? manager.user_name : `${manager.user_email} (pending invite)`))
          .sort()
          .join(', '),
        sites: item.sites,
        siteCount: item.sites.length,
      }))
    },
    myOrganizations(state) {
      return state.userOrganization
    },
  },
  actions: {
    async loadUserOrganizationList({ commit }, user_id) {
      const { data } = await axios.get(`common/user/${user_id}/organization`)
      if (data) {
        // Sort the organizations alphabetically by name
        data.sort((a, b) => a.name.localeCompare(b.name))
        // For each organization, sort its sites alphabetically by name
        data.forEach(org => {
          if (org.sites) {
            org.sites.sort((a, b) => a.name.localeCompare(b.name))
          }
        })
      }
      commit(TYPE.LOAD_USER_ORGANIZATION, data || [])
    },
  },
  mutations: {
    [TYPE.LOAD_USER_ORGANIZATION](state, data) {
      state.userOrganization = data || []
    },
  },
}
