import * as Sentry from '@sentry/vue'

export const TYPE = {
  SET_USER: 'SET_USER',
  SET_USER_AUDIT_GOOGLE_CREDENTIALS: 'SET_USER_AUDIT_GOOGLE_CREDENTIALS',
  SET_IS_ADMIN: 'SET_IS_ADMIN',
}

const localStorageUser = 'user'
const localStorageUserAuditGoogleCredentials = 'userAuditGoogleCredentials'
const localStorageAdmin = 'admin'

export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem(localStorageUser)) || null,
    userAuditGoogleCredentials: JSON.parse(localStorage.getItem(localStorageUserAuditGoogleCredentials)) || null,
    is_admin: JSON.parse(localStorage.getItem(localStorageAdmin)) || null,
  },
  getters: {
    user(state) {
      return state.user
    },
    getIsAdmin(state) {
      return state.is_admin
    },
    getUserName(state) {
      if (!state.user) return null

      return state.user.name ? state.user.name : state.user.email.split('@')[0]
    },
    isSignedIn(state) {
      return state.user !== null
    },
    userAuditGoogleCredentials(state) {
      return state.userAuditGoogleCredentials
    },
    isAdminUser(state) {
      return state?.user?.role?.name === 'admin' ?? false
    },
    isStandardUser(state) {
      return state?.user?.role?.name === 'standard'
    },
  },
  actions: {
    setIsAdmin({ commit }, isAdmin) {
      commit(TYPE.SET_IS_ADMIN, isAdmin)
    },
    async additionalSignup({ commit }, { firstName, lastName }) {
      const user = this.getters['auth/user']
      const res = await axios.patch(`common/user/${user.id}`, {
        name: `${firstName} ${lastName}`,
      })
      commit(TYPE.SET_USER, res.data)
      return res.data
    },
    async signIn({ commit, dispatch }, payload) {
      await dispatch('setIsAdmin', false)

      const res = await axios.post('auth', payload)
      let user = res.data.data.user
      user.is_new_user = res.data.data.is_new_user
      commit(TYPE.SET_USER, res.data.data.user)

      return res.data.data
    },
    async signOut({ dispatch }, onlyClientSide = false) {
      Sentry.configureScope(scope => scope.setUser(null))
      Sentry.configureScope(scope => scope.clear())
      if (!onlyClientSide) {
        await axios.delete('auth')
      }

      return dispatch('clearSensitiveData', null, { root: true })
    },
    async loadMe({ commit }) {
      const response = await axios.get('user/me')
      commit(TYPE.SET_USER, response.data.data)
    },
    async update({ commit }, payload) {
      const response = await axios.put('user/me', payload)
      commit(TYPE.SET_USER, response.data.data)
    },
    clear({ commit }) {
      commit(TYPE.SET_USER, null)
      commit(TYPE.SET_IS_ADMIN, false)
    },
    setAuditCredentials({ commit, getters }, payload) {
      const expectedKeys = ['googleCustomerId', 'googleCustomerName', 'name', 'email']

      // assert that the payloads has at least one of the expected keys
      if (!expectedKeys.some(key => payload[key])) {
        throw new Error('Payload must contain at least one of the following keys: ' + expectedKeys.join(', '))
      }

      for (const expectedKey of expectedKeys) {
        if (payload[expectedKey]) {
          let obj = { ...getters.userAuditGoogleCredentials }
          obj[expectedKey] = payload[expectedKey]
          commit(TYPE.SET_USER_AUDIT_GOOGLE_CREDENTIALS, obj)
        }
      }
    },
    clearAuditSignIn({ commit }) {
      commit(TYPE.SET_USER_AUDIT_GOOGLE_CREDENTIALS, null)
    },
  },
  mutations: {
    [TYPE.SET_USER](state, user) {
      if (user) {
        localStorage.setItem(localStorageUser, JSON.stringify(user))
      } else {
        localStorage.removeItem(localStorageUser)
      }

      state.user = user
    },
    [TYPE.SET_USER_AUDIT_GOOGLE_CREDENTIALS](state, userGoogleCredentials) {
      if (userGoogleCredentials) {
        localStorage.setItem(localStorageUserAuditGoogleCredentials, JSON.stringify(userGoogleCredentials))
      } else {
        localStorage.removeItem(localStorageUserAuditGoogleCredentials)
      }

      state.userAuditGoogleCredentials = userGoogleCredentials
    },
    [TYPE.SET_IS_ADMIN](state, isAdmin) {
      if (isAdmin) {
        localStorage.setItem(localStorageAdmin, 'true')
      } else {
        localStorage.removeItem(localStorageAdmin)
      }
      state.is_admin = isAdmin
    },
  },
}
