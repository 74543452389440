<template>
  <router-link
    :class="{
      'router-link-base router-link-base-active': $route.name.startsWith(menuItem.link),
      'router-link-base router-link-base-hover': !$route.name.startsWith(menuItem.link),
      disabled: menuItem.disabled,
    }"
    :to="{ name: menuItem.link }"
  >
    <span
      role="link"
      class="flex items-center"
    >
      <bb-icon
        v-if="menuItem.bbIcon"
        :icon="menuItem.icon"
        class="nav-icon"
        :title="menuItem.name"
      />
      <component
        :is="menuItem.icon"
        v-else
        class="nav-icon"
        :size="menuItem.iconSize || 24"
      />
      {{ menuItem.name }}
    </span>
    <badge
      v-if="menuItem.beta"
      small
      >Beta</badge
    >
  </router-link>
</template>

<script>
import Badge from '@/components/base/Badge.vue'
import BbIcon from '@/components/shared/bbIcon.vue'

export default {
  name: 'SidebarMenuLink',
  components: { BbIcon, Badge },
  props: ['menuItem'],
}
</script>

<style scoped lang="scss">
.disabled {
  opacity: 0.5 !important;
  pointer-events: none;
}
</style>
