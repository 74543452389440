<template>
  <div class="site-select__wrapper mt-4 rounded-b">
    <search-bar
      ref="siteSearch"
      placeholder=""
      :icon-size="16"
      @filter-term-changed="filterTerm"
    />
    <div
      v-show="!selectedOrganization"
      class="divide-y"
    >
      <router-link :to="{ name: 'portfolio' }">
        <p class="text-bb-text-default my-4 px-6">Portfolio</p>
      </router-link>
      <div class="w-full text-center py-4 px-6">
        <div class="w-full p-1 bg-neutral-50 rounded-full my-auto justify-start items-center inline-flex">
          <!-- Sites Checkbox -->
          <div
            :class="{
              'w-1/2 py-1 rounded-full justify-center items-center gap-3 flex cursor-pointer': true,
              'text-bb-text-secondary font-bold ': portfolioViewMode !== 'site',
              'text-bb-brand-purple bg-white': portfolioViewMode == 'site',
            }"
            @click="togglePortfolioViewMode('site')"
          >
            <div class="p2 font-bold">Accounts</div>
          </div>
          <!-- Organizations Checkbox -->
          <div
            :class="{
              'w-1/2 py-1 rounded-full justify-center items-center gap-3 flex cursor-pointer': true,
              'text-bb-text-secondary ': portfolioViewMode !== 'organization',
              'text-bb-brand-purple  bg-white': portfolioViewMode == 'organization',
            }"
            @click="togglePortfolioViewMode('organization')"
          >
            <div class="p2 font-bold">Organizations</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <OrganizationDropdownMenu
        v-show="portfolioViewMode === 'organization'"
        :organizations="filteredOrganizationList"
        @close="close"
      ></OrganizationDropdownMenu>

      <SiteDropdownMenu
        v-show="portfolioViewMode === 'site'"
        :sites="filteredSites"
        @close="close"
      ></SiteDropdownMenu>
    </div>
    <div
      v-if="!standardUser"
      class="py-5 flex justify-center border border-t-2 border-gray-200 rounded-b"
      @click="addSiteOrOrganization"
    >
      <p><span class="text-lg mr-2">+</span> Add Account</p>
    </div>
  </div>
</template>

<script>
import SearchBar from '@/components/layout/Navbar/OrganizationAndSite/SearchBar.vue'
import OrganizationDropdownMenu from '@/components/layout/Navbar/OrganizationAndSite/OrganizationDropdownMenu'
import SiteDropdownMenu from '@/components/layout/Navbar/OrganizationAndSite/SiteDropdownMenu'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'DropdownMenu',
  components: { SearchBar, OrganizationDropdownMenu, SiteDropdownMenu },
  props: {
    organizations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      query: null,
      sites: [],
      organizationList: [],
      selectedOrganization: null,
    }
  },
  computed: {
    ...mapState('portfolio', ['portfolioViewMode']),
    ...mapState('auth', ['user']),
    standardUser() {
      return this.user.role.name === 'standard'
    },
    filteredSites() {
      let sites = this.sites
      if (this.query) {
        sites = sites.filter(
          site =>
            site.label.toLowerCase().includes(this.query.toLowerCase()) ||
            site.site_organization_name.toLowerCase().includes(this.query.toLowerCase()),
        )
      }
      return sites.sort((a, b) => a.label.localeCompare(b.label))
    },
    filteredOrganizationList() {
      if (!this.query) {
        return this.organizationList
      }
      return this.organizationList.filter(
        organization =>
          organization.label.toLowerCase().includes(this.query.toLowerCase()) ||
          organization.sites.some(site => site.name.toLowerCase().includes(this.query.toLowerCase())),
      )
    },
  },
  mounted() {
    this.focusOnSearch()
    this.organizationList = this.organizations.map(organization => {
      return {
        label: organization.name,
        value: organization.id,
        sites: organization.sites,
      }
    })

    this.sites = this.organizations.reduce((acc, organization) => {
      const sites = organization.sites.map(site => {
        const siteIntegration = site.site_integrations.map(integration => integration.data)
        return {
          label: site.name,
          value: site.id,
          url: site.url,
          site_integration: siteIntegration,
          site_organization_name: organization.name,
          site_organization_id: organization.id,
        }
      })
      return acc.concat(sites)
    }, [])
  },
  methods: {
    ...mapMutations('portfolio', ['SET_VIEW_MODE']),
    focusOnSearch() {
      this.$refs.siteSearch.$el.querySelector('input').focus()
    },
    filterTerm(term) {
      this.query = term
    },
    selectOrganization(org) {
      this.query = null
      this.selectedOrganization = org
      const orgSites = this.organizations.find(organization => organization.id === org.value).sites || []

      this.siteList = orgSites.map(site => {
        const siteIntegration = site.site_integrations.map(integration => integration.data)
        return {
          label: site.name,
          value: site.id,
          site_integration: siteIntegration,
          site_organization_name: this.selectedOrganization.label,
          site_organization_id: this.selectedOrganization.value,
        }
      })
    },
    addSiteOrOrganization() {
      if (this.selectedOrganization) {
        this.$router.push({
          name: 'onboarding-wizard',
          query: {
            desiredState: 'SETUP_CONNECTIONS',
            organizationId: this.selectedOrganization.value,
          },
        })
      } else {
        this.$router.push({
          name: 'onboarding-wizard',
          query: {
            desiredState: 'SETUP_CONNECTIONS',
          },
        })
      }
    },
    togglePortfolioViewMode(mode) {
      this.SET_VIEW_MODE(mode)
      this.siteList = []
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.site-select {
  &__wrapper {
    @apply absolute bg-neutral-0;
    top: $header__height--lg;
    width: 320px;
  }
}
</style>
