const state = {
  audits: [],
  auditDetails: {},
  auditSummary: {},
  auditsError: null,
  auditDetailsError: null,
  auditSummaryError: null,
  pendingAuditProgress: 0,
  pendingAuditStatus: '',
  auditExternalCustomers: [],
}

const mutations = {
  SET_AUDITS(state, audits) {
    state.audits = audits
  },
  SET_AUDIT_DETAILS(state, audit) {
    state.auditDetails = audit
  },
  SET_AUDIT_SUMMARY(state, auditSummary) {
    state.auditSummary = auditSummary
  },
  SET_AUDITS_ERROR(state, error) {
    state.auditsError = error
  },
  SET_AUDIT_DETAILS_ERROR(state, error) {
    state.auditDetailsError = error
  },
  SET_AUDIT_SUMMARY_ERROR(state, error) {
    state.auditSummaryError = error
  },
  SET_PENDING_AUDIT_PROGRESS(state, progress) {
    state.pendingAuditProgress = progress
  },
  SET_PENDING_AUDIT_STATUS(state, status) {
    state.pendingAuditStatus = status
  },
  SET_AUDIT_EXTERNAL_CUSTOMERS(state, customers) {
    state.auditExternalCustomers = customers
  },
  SET_AUDIT_CHECKS(state, checks) {
    state.auditDetails.checks = checks
  },
}

const actions = {
  async fetchAudits({ commit }, { siteId, status }) {
    try {
      const response = await axios.get(`/audit/site/${siteId}/report`, {
        params: { status },
      })
      commit('SET_AUDITS_ERROR', null)
      if (response.data.length) {
        response.data.forEach((audit, index) => {
          audit.auditNumber = response.data.length - index
        })
      }
      commit('SET_AUDITS', response.data)
    } catch (error) {
      commit('SET_AUDITS_ERROR', error)
      commit('SET_AUDITS', [])
    }
  },

  updateChecks({ commit }, checks) {
    commit('SET_AUDIT_CHECKS', checks)
  },

  async fetchPendingAuditProgress({ commit, state }, { siteId, auditReportId }) {
    try {
      const response = await axios.get(`/audit/site/${siteId}/report/${auditReportId}`)
      const { progress, status } = response.data

      // Update pendingAuditProgress and pendingAuditStatus in the store
      commit('SET_PENDING_AUDIT_PROGRESS', progress)
      commit('SET_PENDING_AUDIT_STATUS', status)

      // Update the audit in the audits array
      const updatedAudits = state.audits.map(audit => {
        if (audit.id === auditReportId) {
          return {
            ...audit,
            progress,
            status,
          }
        }
        return audit
      })
      commit('SET_AUDITS', updatedAudits)
    } catch (error) {
      console.error('Error fetching pending audit progress:', error)
    }
  },

  async fetchAuditDetails({ commit, state }, { siteId, auditReportId }) {
    try {
      const response = await axios.get(`/audit/site/${siteId}/report/${auditReportId}`)
      const categoryOrder = ['account', 'campaign', 'ads', 'keywords']
      const processedAudit = {
        ...response.data,
        checks: response.data.checks
          .map(check => {
            if (check.score === 100 || check.issues_count === 0) {
              return { ...check, severity: 'passed' }
            }
            if (check.status === 'skipped' && check.status_reason === 'no_brand_terms') {
              return { ...check, status: 'failed' }
            }
            return check
          })
          .sort((a, b) => {
            const categoryComparison = categoryOrder.indexOf(a.category) - categoryOrder.indexOf(b.category)
            if (categoryComparison !== 0) return categoryComparison
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          }),
      }

      commit('SET_AUDIT_DETAILS', processedAudit)

      if (processedAudit.status === 'running' || processedAudit.status === 'pending') {
        if (processedAudit.progress !== state.pendingAuditProgress) {
          commit('SET_PENDING_AUDIT_PROGRESS', processedAudit.progress)
        }
      } else if (processedAudit.status === 'completed') {
        commit('SET_PENDING_AUDIT_PROGRESS', 0)
      }
    } catch (error) {
      commit('SET_AUDIT_DETAILS_ERROR', error)
      commit('SET_AUDIT_DETAILS', null)
    }
  },

  async fetchAuditSummary({ commit }, { siteId, auditReportId }) {
    try {
      const response = await axios.get(`/audit/site/${siteId}/report/${auditReportId}/summary`)
      commit('SET_AUDIT_SUMMARY', response.data)
    } catch (error) {
      commit('SET_AUDIT_SUMMARY_ERROR', error)
      commit('SET_AUDIT_SUMMARY', null)
      return null
    }
  },

  // External audit functions using customerId
  async fetchExternalAudits({ commit }, { customerId, status }) {
    try {
      const response = await axios.get(`/audit/customer/${customerId}/report`, {
        params: { status },
      })
      commit('SET_AUDITS_ERROR', null)
      if (response.data.length) {
        response.data.forEach((audit, index) => {
          audit.auditNumber = response.data.length - index
        })
      }
      commit('SET_AUDITS', response.data)
    } catch (error) {
      commit('SET_AUDITS_ERROR', error)
      commit('SET_AUDITS', [])
      return []
    }
  },

  async fetchPendingExternalAuditProgress({ commit, state }, { customerId, auditReportId }) {
    try {
      const response = await axios.get(`/audit/customer/${customerId}/report/${auditReportId}`)
      const { progress, status } = response.data

      // Update pendingAuditProgress and pendingAuditStatus in the store
      commit('SET_PENDING_AUDIT_PROGRESS', progress)
      commit('SET_PENDING_AUDIT_STATUS', status)

      // Update the audit in the audits array
      const updatedAudits = state.audits.map(audit => {
        if (audit.id === auditReportId) {
          return {
            ...audit,
            progress,
            status,
          }
        }
        return audit
      })
      commit('SET_AUDITS', updatedAudits)
    } catch (error) {
      console.error('Error fetching pending audit progress:', error)
    }
  },

  async fetchExternalPendingAuditProgress({ commit, state }, { customerId, auditReportId }) {
    try {
      const response = await axios.get(`/audit/customer/${customerId}/report/${auditReportId}`)
      const { progress, status } = response.data

      // Update pendingAuditProgress and pendingAuditStatus in the store
      commit('SET_PENDING_AUDIT_PROGRESS', progress)
      commit('SET_PENDING_AUDIT_STATUS', status)

      // Update the audit in the audits array
      const updatedAudits = state.audits.map(audit => {
        if (audit.id === auditReportId) {
          return {
            ...audit,
            progress,
            status,
          }
        }
        return audit
      })
      commit('SET_AUDITS', updatedAudits)
    } catch (error) {
      console.error('Error fetching pending audit progress:', error)
    }
  },

  async fetchExternalAuditDetails({ commit }, { customerId, auditReportId }) {
    try {
      const response = await axios.get(`/audit/customer/${customerId}/report/${auditReportId}`)
      // Process checks to update severity to passed if score is 100 or issues_count is 0
      const categoryOrder = ['account', 'campaign', 'ads', 'keywords']
      const processedAudit = {
        ...response.data,
        checks: response.data.checks
          .filter(check => check.status_reason !== 'no_brand_terms')
          .map(check => {
            if (check.score === 100 || check.issues_count === 0) {
              return { ...check, severity: 'passed' }
            }
            return check
          })
          .sort((a, b) => {
            const categoryComparison = categoryOrder.indexOf(a.category) - categoryOrder.indexOf(b.category)
            if (categoryComparison !== 0) return categoryComparison
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          }),
      }

      commit('SET_AUDIT_DETAILS', processedAudit)
    } catch (error) {
      commit('SET_AUDIT_DETAILS_ERROR', error)
      commit('SET_AUDIT_DETAILS', null)
    }
  },

  async fetchExternalAuditSummary({ commit }, { customerId, auditReportId }) {
    try {
      const response = await axios.get(`/audit/customer/${customerId}/report/${auditReportId}/summary`)
      commit('SET_AUDIT_SUMMARY', response.data)
    } catch (error) {
      commit('SET_AUDIT_SUMMARY_ERROR', error)
      commit('SET_AUDIT_SUMMARY', null)
    }
  },

  async fetchAuditExternalCustomers({ commit }) {
    try {
      const response = await axios.get(`/audit/customer/external/report/latest`)
      commit('SET_AUDIT_EXTERNAL_CUSTOMERS', response.data)
    } catch (error) {
      commit('SET_AUDIT_EXTERNAL_CUSTOMERS', null)
    }
  },
  async dismissAuditCheck(_, { siteId, report_id, check_id }) {
    try {
      await axios.post(`/audit/site/${siteId}/report/${report_id}/check/${check_id}/dismiss`)
    } catch (error) {
      console.error('Error dismissing audit check:', error)
    }
  },
  async undoDismissAuditCheck(_, { siteId, report_id, check_id }) {
    try {
      const response = await axios.post(`/audit/site/${siteId}/report/${report_id}/check/${check_id}/dismiss/undo`)
      return response
    } catch (error) {
      console.error('Error undoing dismissed audit check:', error)
    }
  },
}
const getters = {
  audits: state => state.audits,

  latestCompletedAudit: state => {
    if (!state.audits.length) {
      return null
    }
    const completedAudits = state.audits.filter(audit => audit.status === 'completed')
    return completedAudits.length ? completedAudits[0] : null
  },
  latestCreatedAudit: state => {
    if (!state.audits.length) {
      return null
    }
    return state.audits.filter(audit => audit.id).sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0]
  },

  pendingAudit: state => {
    if (!state.audits.length) {
      return null
    }
    return state.audits.find(audit => audit.status === 'running' || audit.status === 'pending')
  },

  getAuditDetails: state => state.auditDetails,
  getAuditSummary: state => state.auditSummary,
  getAuditsError: state => state.auditsError,
  getAuditDetailsError: state => state.auditDetailsError,
  getAuditSummaryError: state => state.auditSummaryError,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
