import dayjs from 'dayjs'

const TYPE = {
  SET_CAMPAIGNS: 'SET_CAMPAIGNS',
  SET_SELECTED_CAMPAIGNS: 'SET_SELECTED_CAMPAIGNS',
  SET_CURRENCY: 'SET_CURRENCY',
  SET_ACCOUNT_ANALYSIS: 'SET_ACCOUNT_ANALYSIS',
  SET_ERROR: 'SET_ERROR',
}

const state = {
  campaigns: [],
  currency: '',
  accountAnalysis: {},
  error: null,
  selectedCampaigns: [],
}

const mutations = {
  SET_CAMPAIGNS(state, campaigns) {
    state.campaigns = campaigns
  },
  SET_SELECTED_CAMPAIGNS(state, campaigns) {
    state.selectedCampaigns = campaigns
  },
  SET_CURRENCY(state, currency) {
    state.currency = currency
  },
  SET_ACCOUNT_ANALYSIS(state, analysis) {
    state.accountAnalysis = analysis
  },
  SET_ERROR(state, error) {
    state.error = error
  },
}

const getters = {
  weeklyDateRange(state) {
    const startDate = dayjs(state.accountAnalysis.campaign_group_data.from)
    const endDate = dayjs(state.accountAnalysis.campaign_group_data.to)
    const dates = []

    let currentDate = startDate

    while (currentDate.isSame(endDate) || currentDate.isBefore(endDate)) {
      dates.push(currentDate.format('DD MMMM YYYY'))
      currentDate = currentDate.add(7, 'day')
    }

    return dates
  },
  metrics(state) {
    return state.accountAnalysis?.campaign_group_data?.metrics
  },
  anomalies(state) {
    return state.accountAnalysis?.output
  },
  startDate(state) {
    return state.accountAnalysis?.campaign_group_data?.from
  },
  endDate(state) {
    return state.accountAnalysis?.campaign_group_data?.to
  },
  sessionId(state) {
    return state.accountAnalysis?.session_id
  },
}

const actions = {
  async getCampaigns({ commit }, siteId) {
    const end_date = dayjs().format('YYYY-MM-DD')
    const start_date = dayjs().subtract(30, 'day').format('YYYY-MM-DD')

    commit(TYPE.SET_ERROR, null)
    try {
      const response = await axios.get(`/search/site/${siteId}/troubleshooting-tree/campaigns`, {
        params: {
          start_date,
          end_date,
        },
      })
      commit(TYPE.SET_CAMPAIGNS, response.data)
    } catch (error) {
      commit(TYPE.SET_ERROR, error)
      throw Error('Failed to load campaigns')
    }
  },
  async analyzeAccount({ commit, state }, siteId) {
    commit(TYPE.SET_ERROR, null)
    try {
      const response = await axios.post(`/search/site/${siteId}/troubleshooting-tree/analyze-campaigns`, {
        campaign_ids: state.selectedCampaigns.map(campaign => campaign.campaign_id),
      })
      commit(TYPE.SET_ACCOUNT_ANALYSIS, response.data)
    } catch (error) {
      commit(TYPE.SET_ERROR, error)
      throw Error('Failed to analyze account')
    }
  },
  async fetchSiteDetails({ commit }, site_id) {
    try {
      const { data: siteDetails } = await axios.get(`/common/site/${site_id}`)
      const currency = siteDetails.currency
      commit(TYPE.SET_CURRENCY, currency)
      return siteDetails
    } catch (error) {
      throw Error('Failed to load site details')
    }
  },
  async sendChatMessage({ commit }, { siteId, sessionId, content }) {
    commit('SET_ERROR', null)
    try {
      const response = await axios.post(
        `/search/site/${siteId}/troubleshooting-tree/chat`,
        { content },
        {
          params: { session_id: sessionId },
          headers: { 'Content-Type': 'application/json' },
        },
      )
      return response.data.message
    } catch (error) {
      console.error('Error sending chat message', error)
      throw error
    }
  },
  updateSelectedCampaigns({ commit }, campaigns) {
    commit(TYPE.SET_SELECTED_CAMPAIGNS, campaigns)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
